window.productPageHandler = function () {
  $(document).ready(function () {
    // bindProductsCategoryEvents();
    bindProductModalSuggestionEvents();
    getHotspots();
  });

  // TODO: spr jak to dziala przy refactorze customizera
  // const bindProductsCategoryEvents = function () {
  //   $('.jsLinkButton').each(function (index) {
  //     $(this).on('click', function (e) {
  //       if ($(this).hasClass('active')) {
  //         $(this).removeClass('active');
  //         $('.jsSlideList').eq(index).slideUp();
  //         $('.jsGalleryOverlay').css('display', 'none'); // ???
  //       } else {
  //         $('.jsLinkButton').removeClass('active');
  //         $(this).addClass('active');
  //         $('.jsSlideList').slideUp();
  //         $('.jsSlideList').eq(index).slideToggle(); // ???
  //         $('.jsGalleryOverlay').css('display', 'block'); // ???
  //       }
  //     });
  //   });
  // };

  // Modal suggestion
  const bindProductModalSuggestionEvents = function () {
    const filesToUpload = [];

    // Add file as a attachment - function
    const suggestionFormFiles = function (filesFromInput, filesToUpload, removeFile = false, idToRemove) {
      if (removeFile) {
        filesToUpload.splice(idToRemove, 1);
      } else {
        let i = 0;
        while (i < filesFromInput.length) {
          const file = filesFromInput[i];
          filesToUpload.push(file);
          i += 1
        }
      }

      $('.jsFileList').html('');
      let j = 0;
      while (j < filesToUpload.length) {
        const file = filesToUpload[j];
        const fileInfo = [
          '<li>',
          '<span class="name">',
          file.name,
          '</span>',
          '<div class="remove" data-remove-file="',
          j,
          '"></div>',
          '</li>'
        ].join('');
        $('.jsFileList').append($(fileInfo)).addClass('active');
        j += 1;
      }
    };

    // Reset attachments container - function
    const suggestionsFormSuccess = function (form, files) {
      const submitButton = $('.jsSuggestionConfirmButton');
      form[0].reset();
      files = [];
      suggestionFormFiles(files, files);
      submitButton.text(gettext('Message has been sent!')).addClass('success');
      setTimeout(function () {
        submitButton.text(gettext('Send')).removeClass('success');
      }, 1000);
    };

    // Modal Suggestion
    $('.jsProductSuggestion').on('click', function () {
      window.rodoCheckbox = true;
      $('.jsModalSuggestion').addClass('active');
    });

    // Add file as a attachment
    $('.jsSuggestionFile').on('change', function (e) {
      suggestionFormFiles(e.target.files, filesToUpload);
    });

    // Remove file as a attachment
    $('.jsFileList').on('click', '.remove', function (e) {
      const indexToRemove = $(e.target).data('remove-file');
      suggestionFormFiles(e, filesToUpload, true, indexToRemove);
    });

    // Submit suggestion modal
    $('.jsSuggestionForm').on('submit', function (e) {
      e.preventDefault();
      const form = $(this);
      const formData = new FormData(form);
      form.attr('action');

      for (const file in filesToUpload) {
        formData.append('files[]', file, file.name);
      }

      for (const formEl in form.serializeArray()) {
        formData.append(formEl.name, formEl.value);
      }

      $.ajax({
        type: form.attr('method'),
        url: form.attr('action'),
        data: formData,
        async: false,
        contentType: false,
        processData: false,
        cache: false,
        success: function (data) {
          suggestionsFormSuccess(form, filesToUpload);
          console.log('elo');
        },
        error: function (data) {
          console.error(data);
        }
      });
    });
  };
};
